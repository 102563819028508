<template>
  <v-row justify="center">
    <v-dialog v-model="show" scrollable max-width="800px" persistent>
      <v-card>
        <v-card-text style="height: 500px" class>
          <div class="ma-6 pa-0 text-center">
            <!-- src="@/assets/notification.png" -->
            <img
              src="@/assets/img/alertbell_mobile.png"
              width="100px"
              height="100px"
              alt="One Box"
            />
          </div>
          <div class="text-center">
            <h2>เรียนผู้ใช้งาน One Box ทุกท่าน</h2>
          </div>
          <div>
            <br />
            <!-- <span style="font-size: 16px;">
                ทาง One Box จะลดจำนวนพื้นที่ใช้งานฟรีของ Citizen One Box จาก 2 GB เหลือ 1 GB สำหรับผู้ใช้งานใหม่ที่สมัครใช้งานตั้งแต่วันที่ 1 เมษายน 2564 เป็นต้นไป
              </span> -->
            <span style="font-size: 16px;" class="ml-4">
              เนื่องด้วยระบบ ONE BOX
              ให้ความสำคัญในเรื่องความยินยอมในการเก็บรวบรวม ใช้
              หรือเปิดเผยข้อมูลส่วนบุคคล
              จึงมีนโยบายในการขอความยินยอมจากผู้ใช้บริการในนามนิติบุคคลและบุคคลธรรมดา
              ในการเข้าถึงและจัดเก็บข้อมูลใบกำกับภาษีอิเล็กทรอนิกส์/ใบกำกับภาษีอิเล็กทรอนิกส์แบบย่อ/ใบลดหนี้อิเล็กทรอนิกส์/ใบเสร็จรับเงินอิเล็กทรอนิกส์ของท่าน
              ตั้งแต่วันที่ 13 กุมภาพันธ์ 2566 เป็นต้นไป
              <!-- <a
                  style="text-decoration: none;"
                  @click="fn_gotodetail()"
               
                  >รายละเอียด Feature One Box Basic และ One Box Enterprise </a
                > -->
              <!-- <a
                      style="text-decoration: none; color:#FF6d01;"
                      @click="fn_gotodetail()"
                      target="_blank"
                      >โดยระบบจะมีการเพิ่มการขอความยินยอมในการเข้าถึงและจัดเก็บเอกสารดังกล่าว ท่านสามารถเลือกยินยอมหรือไม่ยินยอม ก่อนเข้าใช้งานเมนู INBOX (แฟ้มจัดเก็บใบกำกับภาษีอิเล็กทรอนิกส์ที่ท่านได้รับ) และเมนู OUTBOX แฟ้มจัดเก็บใบกำกับภาษีอิเล็กทรอนิกส์ที่ท่านส่งให้ผู้ซื้อสินค้า/บริการ) กรณีนิติบุคคล Admin จะสามารถเลือกยินยอมหรือไม่ยินยอมได้</a
                    > -->
            </span>
            <br />
            <span style="font-size: 16px;" class="ml-4">
              โดยระบบจะมีการเพิ่มการขอความยินยอมในการเข้าถึงและจัดเก็บเอกสารดังกล่าว
              ท่านสามารถเลือกยินยอมหรือไม่ยินยอม ก่อนเข้าใช้งานเมนู INBOX
              (แฟ้มจัดเก็บใบกำกับภาษีอิเล็กทรอนิกส์ที่ท่านได้รับ) และเมนู OUTBOX
              แฟ้มจัดเก็บใบกำกับภาษีอิเล็กทรอนิกส์ที่ท่านส่งให้ผู้ซื้อสินค้า/บริการ)
              กรณีนิติบุคคล Admin จะสามารถเลือกยินยอมหรือไม่ยินยอมได้
            </span>
            <br />
            <span style="font-size: 16px;" class="ml-4">
              - หากเลือกยินยอม ระบบจะทำการจัดเก็บเอกสารดังกล่าวอัตโนมัติ
              และผู้ใช้บริการจะสามารถใช้งานเมนู INBOX
              (แฟ้มจัดเก็บใบกำกับภาษีอิเล็กทรอนิกส์ที่ท่านได้รับ) และเมนู OUTBOX
              แฟ้มจัดเก็บใบกำกับภาษีอิเล็กทรอนิกส์ที่ท่านส่งให้ผู้ซื้อสินค้า/บริการ)
              ได้
            </span>
            <br />
            <span style="font-size: 16px;" class="ml-4">
              - หากเลือกไม่ยินยอม ระบบจะไม่ทำการจัดเก็บเอกสารดังกล่าว
              และผู้ใช้บริการจะไม่สามารถใช้งานเมนู INBOX
              (แฟ้มจัดเก็บใบกำกับภาษีอิเล็กทรอนิกส์ที่ท่านได้รับ) และเมนู OUTBOX
              แฟ้มจัดเก็บใบกำกับภาษีอิเล็กทรอนิกส์ที่ท่านส่งให้ผู้ซื้อสินค้า/บริการ)
              ได้
            </span>
            <br />
            <span style="font-size: 16px;" class="ml-4">
              หลังจากการเลือกความยินยอมแล้ว หากต้องการแก้ไขความยินยอม
              สามารถดำเนินการแก้ไขได้ภายหลังจากฟังก์ชั่นการแก้ไขความยินยอม
            </span>
          </div>
          <br />
        <div class="text-center">
          <img
            :src="require('@/assets/img/1.jpg')"
            width='600px'
          >
        </div>
        <br />
        <div class="text-center">
          <img
            :src="require('@/assets/img/2.jpg')"
            width='600px'
          >
        </div>
        <br />
        <div class="text-center">
          <img
            :src="require('@/assets/img/3.jpg')"
            width='600px'
          >
        </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="text-center pa-2">
          <v-checkbox
            v-model="checkshow"
            class="mx-2"
            label="ไม่ต้องการให้แสดงอีก"
          ></v-checkbox>
          <!-- color="green darken-1"  -->
          <v-btn small color="#184966" class="white--text ma-2" @click="checkdialog()"
            >รับทราบ</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import VueCookies from "vue-cookies";
export default {
  props: ["show"],
  data: function() {
    return {
      checkshow: false,
      dialog: false,
    };
  },
  methods: {
    checkdialog() {
      // VueCookies.set('checkalert', true,60*60*24);
      if (this.checkshow == false) {
        VueCookies.set("checkalert", true, 60 * 60 * 24);
        localStorage.setItem("checkshow", this.checkshow);
        this.$emit("closedialog");
      } else if (this.checkshow == true) {
        VueCookies.set("checkalert", true);
        this.$emit("closedialog");
      }
    },
    check_loop() {
      this.checkdialog();
    },
    fn_gotodetail() {
      //  this.$router.push('/test')
      // let url = this.$router.push('/detailfeature');
      let url;
      if (process.env.NODE_ENV === "production") {
        url = "https://box.one.th/detailfeature";
      } else {
        url = "https://uatbox-dedicate.one.th/detailfeature";
      }
      window.open(url, "_blank");
    },
  },
};
</script>
